*, *::before, *::after {
  box-sizing: border-box;
}

a, button {
  color: #007bff;
  text-decoration: none;

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &.ressource {
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #ccc;
    background: white;
    display: block;
    position: relative;

    &:hover {
      background: #E9E9E9;
    }

    &::after {
      content: '>';
      position: absolute;
      top: 4px;
      right: 10px;
      font-size: 1.4em;
      font-weight: 700;
      color: black;
    }
  }

  &.btn {
    color: white;
    font-weight: 500;
    font-size: 1em;
    cursor: pointer;
    background: none;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    outline: none;
    background: #520b4e;
    color: white;

    &:hover {
      background-color: #863781;
    }

    &.center {
      margin: 0px auto;
    }

    &.icon {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        margin: -3px 0px;
      }
    }

    &.normal {
      background: #F9F9F9;
      border: 1px solid #e9e9e9;
      color: black;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        background: #F9F9F9;
      }
    }

    &.clear {
      background: white;
      font-weight: 500;
      padding: 5px;

      &:hover {
        background-color: #ceddff;
      }
    }

    &.grey {
      background: none;
      color: grey;
      font-size: 0.8em;
      padding: 5px 10px;

      &:hover {
        color: #007bff;
        background-color: #F9F9F9;
      }
    }

    &.light {
      background: white;
      font-weight: 500;
      padding: 0px;
      color: blue;

      &:hover {
        color: #0b5ed7;
      }
    }

    &.red {
      color: #df3e3e;
      background: #ffcece;
    }

    &.small {
      padding: 5px;
    }

    &.full {
      width: 100%;
    }

    &:disabled {
      color: grey;
      cursor: not-allowed;
      background: #D9D9D9;
    }
  }
}

.list-page {
  .filters {
    margin: 20px;
    margin-bottom: 0px;
    display: flex;

    &> * + * {
      margin-left: 20px;
    }

    .filter {
      
      &.full {
        flex: 1;
      }
    }

    .sub-filters {
      margin: 0px;

      &> * + * {
        margin-left: 20px;
      }
  
      .filter {
        
        &.full {
          flex: 1;
        }
      }
    }
  }
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
}


.type-selector {
  padding: 40px 16px;

  .list {
    display: flex;
    gap: 8px;
    justify-content: center;

    .type {
      border: 1px solid #ccc;
      border-radius: 8px;
      aspect-ratio: 1;
      width: 250px;
      height: 250px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 6px;
      justify-content: center;
      text-align: center;
      font-size: 1.2em;
      cursor: pointer;

      &:hover {
        background-color: #eaeaea;
      }
    }
  }

  .more {
    text-align: center;
    margin-top: 16px;
    color: grey;
  }
}

.binding-selector {
  width: 100%;

  .head {
    padding: 16px;
  }

  .products {
    display: flex;
    flex-direction: column;
    &> * + * {
      border-top: 1px solid #E9E9E9;
    }

    .product {
      display: flex;
      align-items: center;
      // cursor: pointer;
      gap: 8px;
      padding: 16px;

      // &:hover {
      //   background: #F9F9F9;
      // }

      img, .fake-img {
        background-color: #F9F9F9;
        flex: 0 0 auto;
        width: 70px;
        object-fit: cover;
        border-radius: 4px;
        height: 110px;
        border: none;
      }

      .infos {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
          font-weight: 700;
        }

        .variants {
          display: flex;
          flex-direction: column;
          gap: 0px;

          .variant {
            cursor: pointer;
            border-top: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            padding: 8px;

            &:first-child {
              border-radius: 8px 8px 0px 0px;
            }

            &:last-child {
              border-radius: 0px 0px 8px 8px;
              border-bottom: 1px solid #ccc;
            }

            &:first-child:last-child {
              border-radius: 8px;
              border-bottom: 1px solid #ccc;
            }

            &:hover {
              background-color: #cacaca;
            }
            
            .name {
              padding-bottom: 4px;
            }

            .options {
              display: flex;
              gap: 8px;
              background: black;

              &:nth-child(2) {
                border-radius: 4px 4px 0px 0px;
              }

              &:last-child {
                border-radius: 0px 0px 4px 4px;
              }

              &:nth-child(2):last-child {
                border-radius: 4px;
              }
    
              .option {
                color: white;
                font-size: 0.8em;
                padding: 4px 12px;
              }
            }
          }
        }
      }
    }
  }
}